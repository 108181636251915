<template>
  <div class="home-view">
    <b-navbar
      type="light"
      variant="healex"
      fixed="top"
      toggleable="md"
      class="shadow-sm"
    >
      <b-container>
        <b-navbar-brand :to="{ name: 'CasesList' }">
          <img src="@/assets/logo-black.png" class="logo-nav" alt />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item :to="{ name: 'CasesList' }" exact id="HomeHomeButton">
              <b-icon-house-fill />{{ $t("views.home.title") }}
            </b-nav-item>

            <b-nav-item-dropdown
              right
              v-if="
                getAllowSettingScreens &&
                subMenuItems &&
                subMenuItems.length > 0
              "
              id="HomeSettingsDropdown"
            >
              <template v-slot:button-content>
                <b-icon icon="gear-fill" aria-hidden="true"></b-icon
                >{{ $t("views.home.Settings") }}
              </template>
              <b-dropdown-item
                v-for="subMenu in subMenuItems"
                v-bind:key="subMenu.label"
                :to="{ name: subMenu.target }"
                :id="'HomeSettingsOption' + subMenu.screen"
                >{{ $t(subMenu.label) }}</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'Help' }" id="HomeHelp">
              <b-icon-question-circle class="mr-1" />{{
                $t("views.home.help")
              }}</b-nav-item
            >
            <!-- <OrganizationUnitDropDown v-if="getDisplayOU" /> -->
            <locale-changer />
            <b-nav-item-dropdown right id="HomeUser">
              <!-- Using 'button-content' slot -->
              <template v-slot:button-content>
                <em>{{ UserName }}</em>
              </template>
              <b-dropdown-item :to="{ name: 'UserProfile' }" id="HomeProfile">{{
                $t("views.home.Profile")
              }}</b-dropdown-item>
              <b-dropdown-item href="#" @click="logOut" id="HomeSignOut">{{
                $t("views.home.SignOut")
              }}</b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item :to="{ name: 'about' }" id="HomeAbout">{{
                $t("views.home.about")
              }}</b-dropdown-item>
              <b-dropdown-item
                @click="$bvModal.show('data-privacy-modal')"
                id="HomeDataPrivacy"
                >{{ $t("dataPrivacy.title") }}</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <b-container class="view-container py-3">
      <transition name="slide-fade">
        <router-view />
      </transition>
    </b-container>
    <b-navbar
      type="light"
      variant="healex"
      fixed="bottom"
      class="bg-healex-gradient"
    >
      <b-container>
        <b-navbar-nav class="ml-auto">
          <b-nav-form></b-nav-form>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
    <DataPrivacyModal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import OrganizationUnitDropDown from "@/components/OrganizationUnitDropDown";
import localeChanger from "@/components/general/locale-changer";
import DataPrivacyModal from "@/components/general/data-privacy";

export default {
  name: "Home",
  components: {
    // OrganizationUnitDropDown,
    localeChanger,
    DataPrivacyModal,
  },
  methods: {
    ...mapActions({
      onLogout: "LOGOUT",
    }),
    logOut() {
      this.$oidcClient.getSignedIn().then((signedIn) => {
        if (signedIn) this.$oidcClient.signOut();
      });
      this.$store.dispatch("LOGOUT", { api: this.$api });
    },
  },
  computed: {
    ...mapGetters({
      UserName: "GET_USERNAME",
    }),
    ...mapGetters({
      getAllowSettingScreens: "GET_ALLOWSETTINGSCREEN",
    }),
    ...mapGetters({
      getDisplayOU: "GET_DISPLAY_OU",
    }),
    ...mapGetters({
      subMenus: "GET_SUBMENUS",
    }),
    navSettings() {
      return [
        { target: "Customer", label: "views.home.Customers", screen: 1 },
        { target: "ListMasterForm", label: "views.home.MasterForm", screen: 2 },
        {
          target: "OrgUnits",
          label: "views.home.OrganizationalUnits",
          screen: 3,
        },
        {
          target: "Settings",
          label: "views.home.AdministrativeCosts",
          screen: 4,
        },
        { target: "Users", label: "views.home.Users", screen: 5 },
        { target: "Surcharges", label: "views.home.Surcharges", screen: 6 },
        { target: "Conditions", label: "views.home.Conditions", screen: 7 },
        {
          target: "caseListTemplate",
          label: "views.home.CaseTemplateList",
          screen: 8,
        },
        {
          target: "GeneralSummations",
          label: "views.home.generalSummations",
          screen: 9,
        },
      ];
    },
    subMenuItems() {
      let array = [];
      if (this.subMenus.length > 0) {
        this.subMenus.forEach((x) => {
          array.push(this.navSettings[x.screen - 1]);
        });
      }
      return array;
    },
  },
  created() {
    // this.$store.dispatch("GET_SUBMENU", { api: this.$api });
  },
};
</script>

<style scoped>
.logo-nav {
  max-width: 70px;
}

.view-container {
  margin: 55px auto 16px;
  height: 100%;
}

.view-content {
  height: 100%;
}
.home-view {
  width: 100%;
  height: calc(100% - 71px);
}
</style>
